import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/docatra/dingusgames/src/components/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`The Cardboard Commandments`}</h1>
    <hr />
    <p><em parentName="p">{`We want to craft the most memorable, gratifying, and silly experiences available to families and friends. We want our players to crack open our games and `}<strong parentName="em">{`laugh`}</strong>{`. We want them to get `}<strong parentName="em">{`angry`}</strong>{` at each other. We want them to leave our games impassioned, and wanting nothing more than to dicuss the experience they just had, except to possibly play `}<strong parentName="em">{`one more game.`}</strong>{` We at Dingus Games believe that the best games are about `}<strong parentName="em">{`people`}</strong>{`, and there's nothing better in game design than making players feel like they have the `}<strong parentName="em">{`freedom`}</strong>{` to express not only themselves and the ways they like to play, but how they respond to the trials and tribulations of wanting to take their victory from (or share it with) other players.`}</em></p>
    <p>{`Dingus Games in an independent game publisher founded and ran by game designers Raith Hamzah and Richard Wyatt (dinguses in their own right, but merely coincidentally). Based out of Austin, Texas, and soon to be ditributed worldwide, Dingus Games works to produce striking tabletop games with satisfying strategic thinking and dynamic social exchange. Our first project, Threshold, will be launching on Kickstarter this year.`}</p>
    <p>{`Over the years, the designers here at Dingus Games have formed strong opinions on what they enjoy in games, what goal posts to strive for, what ideals loft themselves above the rest.`}</p>
    <p>{`These are the mantras that runs rampant in the whims of the cardboard gods we pray to — these are `}<strong parentName="p">{`the Cardboard Commandments`}</strong>{` which dictate the will of our designs.`}</p>
    <h3>{`Thus Reads the Commandments:`}</h3>
    <hr />
    <ul>
      <li parentName="ul"><strong parentName="li">{`Thou Shalt Hold Agency:`}</strong>{` Decisions matter. All decisions matter. There are no correct decisions, only those that feel true. Games must be at the player's whim.`}</li>
      <li parentName="ul"><strong parentName="li">{`Thou Shalt Chase Delight:`}</strong>{` Interesting elements in games must be thrown out if they are not fun. Interesting is not the same as fun. And there is always fun to be had that `}<em parentName="li">{`is`}</em>{` interesting.`}</li>
      <li parentName="ul"><strong parentName="li">{`Thou Shalt Covet Drama:`}</strong>{` Games should strive to allow for memorable, dramatic moments, moments that players will enjoy discussing for a long time. Climactic moments are created through tension, so players must be invested in what happens.`}</li>
      <li parentName="ul"><strong parentName="li">{`Thou Shalt Explore Depths:`}</strong>{` Complicated games with many rules can be fun, but it is more striking to create games that are strategically deep AND easy to learn. We love games that give players the opportunity to get much better at them while still being enjoyable to those who've never played.`}</li>
      <li parentName="ul"><strong parentName="li">{`Thou Shalt Not Be Arbitrary:`}</strong>{` Game rules must be intuitive and such that players will not have to hear the way they work a second time. Rules must make sense thematically and keywords must be self-descriptive.`}</li>
      <li parentName="ul"><strong parentName="li">{`Thou Shalt Seek New Worlds:`}</strong>{` Games are not just mechanics. They are places, they are times, they are characters. Games are special when they can transport you somewhere else, where you are free to be what you want or what you are afraid to be.`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      